.App {
    text-align: center;
    color: #ffffff;
}

.FullName {
    font-size: 80px;
}

.JobTitle {
    font-style: italic;
    margin-bottom: 30px;
}
