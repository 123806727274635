.Icons {
    text-align: center;
    color: #ffffff;
}

.Icon {
    margin: 20px;
    transition: transform .2s;
}

.Icon:hover {
    transform: scale(1.5);
}

a {
    color: inherit;
    text-decoration: none;
}

.Envelope:hover {
    color: #fc0303;
}

.LinkedIn:hover {
    color: #007bb5;
}

.GitHub:hover {
    color: #211f1f;
}

.Instagram:hover {
    color: #cd486b;
}

.Twitter:hover {
    color: #55acee;
}

.Facebook:hover {
    color: #3b5998;
}

.Youtube:hover {
    color: #bb0000;
}

.Twitch:hover {
    color: #6441a4;
}

.Steam:hover {
    color: #66c0f4;
}
